.loginCont {
  padding-left: 15px;
  padding-right: 15px;
}

.loginHead {
  display: flex;
  justify-content: space-between;
  padding-top: 6px;
}

.loginText h2 {
  color: #cc017e;
  font-weight: 900;
  margin-top: 2px;
}

.loginText h2 span {
  color: #85d526;
  font-weight: 900;
  margin-top: 2px;
}

.loginInfo h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 800;
  font-size: 22px;
}

.loginInfo h3 {
  padding-left: 1px;
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 700;
  color: blue;
}

.loginInfo h4 {
  padding-left: 1px;
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 500;
  width: 90%;
}

.otpInfo h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 800;
}

.otpInfo h4 {
  padding-left: 1px;
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 500;
  width: 90%;
  color: blue;
}

.loginBox {
  margin-top: 30px;
}

.loginData {
  margin-top: 8px;
}

.loginData label {
  font-size: small;
}

.loginInput {
  display: flex;
  position: relative;
}

.loginInput input {
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  padding: 10px;
  border-radius: 6px;
  margin-top: 1px;
  border: 1px solid gray;
}

.hideIcon {
  position: absolute;
  right: 0;
  top: 5px;
  right: 7px;
}

.loginBtn button {
  width: 103%;
  background-color: black;
  color: white;
  border-radius: 7px;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  border: none;
}

.signupBtn button {
  width: 102%;
  background-color: black;
  color: white;
  border-radius: 7px;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  border: none;
  margin-top: 12px;
}

.signupTerms {
  text-align: center;
}

.signupTerms h4 {
  margin-top: 18px;
  margin-bottom: 0;
  color: gray;
  font-size: 15px;
}

.signupTerms span {
  color: blue;
}

.loginTerms {
  text-align: center;
}

.loginTerms h6 {
  margin-top: 40px;
  color: gray;
}

.loginTerms span {
  color: blue;
}

.lang {
  display: flex;
}

.lang i {
  padding-top: 8px;
}

.lang h5 {
  margin-top: 0;
  padding-top: 7.5px;
  padding-left: 5px;
  font-weight: 400;
  font-size: 14px;
}

.passLink h5 {
  color: blue;
  font-weight: 800;
  margin-top: 8px;
  margin-bottom: 12px;
  padding-left: 2px;
}

.loginBottom {
  padding-left: 1px;
  width: 100%;
  margin-top: 40%;
  /* position: absolute; */
  /* bottom: 100px; */
  background-color: white;
}

a {
  text-decoration: none;
}

.otpData {
  display: flex;
  margin-top: 12px;
}

.otpData h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 800;
}

.otpData h5 {
  padding-top: 2px;
  padding-left: 12px;
  margin-top: 0;
  margin-bottom: 0;
  color: blue;
  font-weight: 900;
}

.otpInput input {
  width: 94.5%;
  font-size: 14px;
  font-weight: 700;
  padding: 10px;
  border-radius: 6px;
  margin-top: 1px;
  border: 1px solid gray;
  text-align: center;
}

.otpInput2 input {
  width: 94.5%;
  font-size: 14px;
  font-weight: 700;
  padding: 10px;
  border-radius: 6px;
  margin-top: 17px;
  border: 1px solid gray;
  text-align: center;
}

.validOtp h5 {
  margin-top: 9px;
  color: orange;
  padding-left: 2px;
}

.bottomNav {
  background-color: white;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  border-top: 0.5px solid gray;
}

.botInfo {
  text-align: center;
  padding-top: 9px;
}

.botInfo:hover {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  height: 78px;
  border-top: 2px solid black;
  color: black;
}

.botInfo h5 {
  color: rgb(85, 83, 83);
  padding-top: 3px;
  margin-top: 0;
  margin-bottom: 0;
}

.botImg i {
  font-size: 20px;
  color: rgb(85, 83, 83);
  margin-top: 0;
  margin-bottom: 0;
}

.navbody {
  background-color: white;
  height: 55px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  margin-right: 3px;
}

.navText h2 {
  padding-left: 13px;
  font-weight: 700;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 15px;
}

.navIcon {
  display: flex;
  margin-top: 0;
  margin-right: 4px;
  padding-top: 10px;
}

.navIconImg i {
  margin-top: 8px;
  margin-left: 6px;
  margin-right: 4px;
  font-size: 20px;
  color: black;
}

.navIconImg2 {
  margin-left: 14px;
  margin-top: 7px;
  margin-right: 2px;
  font-size: 20px;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: black !important;
  bottom: 10px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: black !important;
}

.css-19kzrtu {
  padding: 0 !important;
  margin-top: 8px !important;
  /* margin-right: 4px !important; */
}

.checkinfilter {
  display: flex;
  overflow-x: scroll;
  margin-top: 8px;
}

.checkfilter {
  margin-right: 7px;
}

.checkfilter h4:hover {
  border: 2px solid black;
  color: black;
}

.checkfilter h4 {
  font-size: small;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 3px 10px;
  margin-top: 0;
  margin-bottom: 0;
  color: gray;
}

.allActiveCards {
  background-color: rgb(241, 241, 241);
  padding-top: 3px;
  margin-top: 10px;
  padding-left: 12.5px;
}

.activeCards {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 5px;
}

.activeCard {
  display: grid;
  padding: 15px;
  margin-top: 15px;
  margin-right: 16px;
  border: 1px solid gray;
  border-radius: 11px;
  background-color: white;
}

.activeText {
  width: 260px;
  display: flex;
  justify-content: space-between;
}

.activeText h2 {
  margin: 0;
  font-size: 18px;
  color: red;
  font-weight: 900;
  padding-bottom: 2px;
}

.activeText i {
  padding-top: 2px;
  font-size: 22px;
  font-weight: 800;
  color: blue;
}

.activeText2 h3 {
  margin: 0;
  font-weight: 800;
  font-size: 15px;
}

.activeText2 h5 {
  margin-top: 4px;
  margin-bottom: 0;
  color: gray;
}

.activeTag {
  display: flex;
  padding-left: 1px;
  margin-bottom: 2px;
  margin-top: 76px;
}

.activeTag h5 {
  margin-top: 0;
  margin-bottom: 8px;
  padding-left: 6px;
  color: rgb(26, 202, 26);
}

.activeTag i {
  color: rgb(26, 202, 26);
}

.activeBtn1 button {
  width: 100%;
  background-color: black;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 7px;
  font-size: 15px;
  font-weight: 700;
}

.activeHead h2 {
  font-size: 16px;
  font-weight: 800;
  margin-top: 8px;
  margin-bottom: 0;
}

.priceCardHead h2 {
  font-size: 16px;
  font-weight: 800;
  margin-top: 2px;
  margin-bottom: 0;
}

.priceCardHead h5 {
  margin-top: 4px;
  margin-bottom: 0;
  color: rgb(88, 85, 85);
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  min-height: 0 !important;
  font-size: 12px !important;
  padding: 12px 16px !important;
  position: static !important ;
}

.viewBtn button {
  background-color: white;
  color: black;
  border: 1px solid gray;
  border-radius: 7px;
  font-size: 13px;
  font-weight: 800;
  padding: 11px;
  margin-top: 8px;
  width: 100%;
}

.bookDetail {
  border: 1px solid gray;
  border-radius: 7px;
  margin-bottom: 12px;
  margin-top: 10px;
  padding: 6px 8px;
  width: 94%;
}

.bookText {
  display: flex;
  justify-content: space-between;
}

.bookText h4 {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 1px;
  margin-right: 14px;
}

.bookId {
  display: flex;
  column-gap: 4px;
}

.bookId h5 {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 14px;
  color: gray;
}

.bookBtn button {
  margin-bottom: -10px;
  width: 75px;
  padding: 4px 6px;
  font-size: 11px;
  font-weight: 600;
  border-radius: 5px;
  background-color: black;
  color: white;
  border: none;
}

.bookBtn button:hover {
  background: white;
  color: black;
  border: 2px solid black;
}

.bookBtn2 button {
  width: fit-content;
  padding: 6px 8px;
  font-size: 11px;
  font-weight: 600;
  border-radius: 5px;
  background-color: black;
  color: white;
  border: none;
}

.bookBtn2 button:hover {
  background: white;
  color: black;
  border: 2px solid black;
}

.bookDataCont {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 70px;
}

.tabPosition {
  position: relative;
}

.bookIcon {
  position: absolute;
  right: 0;
  padding-top: 7px;
  padding-right: 8px;
}

.css-i4bv87-MuiSvgIcon-root {
  fill: black !important;
}

.activeHead span {
  font-size: small;
  border: 1px solid black;
  border-radius: 20px;
  padding: 0px 6px;
}

.checkDetail {
  border: 1px solid gray;
  border-radius: 7px;
  padding: 8px 10px;
  margin-top: 8px;
  margin-bottom: 12px;
}

.checkInfo {
  display: flex;
}

.checkIcon i {
  font-size: 26px;
  color: orange;
  background-color: rgb(252, 240, 219);
  padding: 6px 6px;
  border-radius: 14px;
  margin-top: 1px;
}

.checkHead {
  padding-left: 6px;
}

.checkHead h4,
.checkHead h5 {
  margin-top: 0;
  margin-bottom: 0;
}

.checkText h4 {
  margin-top: 7px;
  margin-bottom: 0;
  font-size: 14px;
}

.checkText h5 {
  font-size: 11px;
  margin-top: 0;
  margin-bottom: 0;
  color: gray;
}

.checkBtn button {
  background-color: white;
  color: black;
  border: 1px solid gray;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 800;
  padding: 7px;
  margin-left: 1px;
  margin-right: 1px;
  margin-top: 12px;
  margin-bottom: 8px;
  width: 100%;
}

.walletCard {
  border: 1px solid gray;
  border-radius: 7px;
  padding: 13px 10px;
  margin-top: 8px;
}

.walletText {
  display: flex;
  justify-content: space-between;
}

.walletText h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.walletText i {
  padding-top: 3px;
  font-size: 15px;
}

.walletText2 h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
}

.walletInput {
  margin-top: 10px;
  margin-bottom: 2px;
}

.walletInput input {
  width: 60%;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 7px 5px;
}

.walletInput button {
  font-size: 12px;
  background-color: black;
  color: white;
  padding: 10px 14px;
  border-radius: 5px;
  border: none;
  margin-left: 5px;
}

.guestAllRate {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: 7px;
}

.guestRate h5 {
  margin-top: 0;
  font-size: 12px;
  margin-bottom: 1px;
}

.guestRate i {
  color: orange;
  padding-right: 4px;
}

.rateBtn button {
  background-color: black;
  color: white;
  border: 1px solid gray;
  border-radius: 7px;
  font-size: 13px;
  font-weight: 800;
  padding: 9px;
  margin-left: 1px;
  margin-right: 1px;
  margin-top: 9px;
  margin-bottom: 7px;
  width: 100%;
}

.guestCard {
  border: 1px solid gray;
  border-radius: 7px;
  padding: 8px 10px;
  margin-top: 18px;
}

.guestText h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 800;
}

.guestReview h6 {
  margin-top: 6px;
  margin-bottom: 0px;
}

.homeAllInfo {
  margin-left: 12.5px;
  margin-right: 12.5px;
}

.viewAll {
  text-align: center;
}

.viewAll h5 {
  padding-bottom: 15px;
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0;
  color: blue;
  font-size: 14px;
}

.viewAll i {
  padding-left: 13px;
  font-size: 15px;
  font-weight: 800;
}

.homeAllInfo2 {
  margin-bottom: 76px;
  margin-top: 0;
  margin-left: 12.5px;
  margin-right: 12.5px;
}

.programInfo {
  display: flex;
  padding-top: 9px;
}

.programText {
  padding-left: 15px;
  padding-top: 4px;
}

.programText h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.programText h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: gray;
}

.programScroll {
  padding-top: 5px;
  padding-left: 1px;
}

.manageCards {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 11px;
  margin-top: 8px;
}

.manageCard {
  border: 1px solid gray;
  border-radius: 7px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 9px;
  padding-right: 9px;
  margin-bottom: 9px;
}

.manageText {
  display: flex;
  justify-content: space-between;
}

.manageText h5 {
  margin-top: 0;
  margin-bottom: 0;
  color: gray;
}

.manageText i {
  color: gray;
}

.manageText2 h5 {
  padding-top: 7px;
  margin-top: 0px;
  margin-bottom: 0;
  font-weight: 800;
}

.css-i4bv87-MuiSvgIcon-root {
  /* font-size: 12px !important; */
  fill: gray !important;
  padding-top: 3px;
}

.allMiniCards {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 5px;
}

.miniCard {
  border: 1px solid gray;
  border-radius: 10px;
  width: 150px;
  margin-right: 14px;
}

.miniCardInfo {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.miniCardInfo h4 {
  margin-top: 0;
  margin-bottom: 0;
  color: gray;
}

.miniCardInfo i {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 800;
  color: blue;
}

.miniCardData {
  padding: 10px;
  width: 150px;
}

.miniCardData h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 800;
}

.miniCardData2 {
  padding: 10px;
  background-color: rgb(241, 241, 241);
  border-radius: 10px;
  margin-top: 8px;
}

.miniCardData2 h5 {
  margin-top: 0;
  margin-bottom: 0;
  color: gray;
}

.topHr hr {
  margin-top: 1px;
}

.topCardData {
  margin-left: 12.5px;
  margin-right: 12.5px;
  margin-top: 12px;
}

.topHead {
  display: flex;
  justify-content: space-between;
  padding-left: 3px;
  padding-right: 8px;
}

.topHeadInfo h2 {
  font-size: 16px;
  font-weight: 800;
  margin-top: 0px;
  margin-bottom: 0;
}

.topHeadInfo h5 {
  margin-top: 5px;
  margin-bottom: 0;
  color: gray;
}

.topHeadInfo span {
  background-color: orange;
  color: white;
  border-radius: 4px;
  font-size: x-small;
  font-weight: 700;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 2px;
  padding-right: 2px;
  margin-left: 5px;
}

.topHeadIcon i {
  margin-top: 0;
  margin-bottom: 0;
  color: orange;
  font-size: 32px;
  padding-top: 5px;
}

.topCard {
  border: 1px solid gray;
  border-left: 3px solid orange;
  border-radius: 7px;
  padding: 12px 11px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.topCardInfo {
  display: flex;
  justify-content: space-between;
  padding-right: 8px;
}

.topCardInfo h6 {
  color: gray;
  margin-top: 0;
  margin-bottom: 0;
}

.topCardInfo i {
  padding-right: 3px;
  color: orange;
}

.topCardText {
  margin-top: 20px;
  margin-bottom: 5px;
}

.topCardText h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}

.topCardText button {
  width: 100%;
  color: white;
  background-color: black;
  padding: 8px;
  border-radius: 6px;
  border: none;
  margin-top: 12px;
}

.css-9emuhu-MuiPaper-root-MuiDrawer-paper {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  height: 270px !important;
}

.bottomInfo {
  display: flex;
  justify-content: space-between;
  /* width: 66%; */
  padding-top: 20px;
  padding-left: 10px;
}

.bottomInfo h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.bottomInfo i {
  font-size: 24px;
  color: gray;
  margin-top: 1px;
}

.bottomCard {
  display: flex;
  background-color: rgb(221, 220, 220);
  padding: 10px;
  margin-top: 25px;
  margin-bottom: 50%;
}

.bottomCardImg img {
  width: 40px;
  height: 40px;
  margin-top: 9px;
  border-radius: 5px;
}

.bottomText {
  padding-left: 16px;
}

.bottomText h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 17px;
}

.bottomText h4 {
  margin-top: 2px;
  margin-bottom: 0;
  color: gray;
}

.bookCont {
  position: relative;
}

.addBooking {
  position: fixed;
  right: 0;
  bottom: 70px;
  padding: 14px 16px;
  border-radius: 40px;
  margin-right: 12.5px;
  background-color: black;
}

.addBooking i {
  color: white;
  font-size: 24px;
  font-weight: 400;
}

/* UpcomingBook */

.upcomingCont {
  padding-left: 12.5px;
  padding-right: 12.5px;
}

.upcomingHead h4 {
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: 800;
}

.upcomingText {
  margin-top: 20px;
}

.upcomingText h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
}

.upcomingText h6 {
  margin-top: 0;
  margin-bottom: 0;
  color: gray;
}

.upcomingAllData {
  border-radius: 9px;
  background-color: rgb(236, 236, 236);
  padding-top: 8px;
  padding-bottom: 7px;
  margin-top: 11px;
  margin-bottom: 20px;
  border: 1px solid gray;
}

.upcomingData {
  display: flex;
  padding-top: 0px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
}

.upcomingInfo h4 {
  margin-top: 0;
  margin-bottom: 0;
  color: red;
  font-weight: 800;
}

.upcomingInfo h5 {
  margin-top: 3px;
  margin-bottom: 0;
}

.upcomingSwitch {
  padding-top: 11px;
}

.upcomingHr {
  padding-left: 10px;
  padding-right: 10px;
}
/*  */

/* InhouseBook CSS */

.inhouseImg img {
  width: 100%;
  margin-bottom: 20px;
}

.inhouseImg h2 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 800;
}

.inhouseImg h4 {
  text-align: center;
  margin-top: 3px;
  margin-bottom: 0;
  color: gray;
  font-size: 16px;
  margin-left: 16px;
  margin-right: 16px;
}
/*  */

/* Add Booking */

.backIcon {
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 0;
}

.header h2 {
  color: black;
  font-weight: 700;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 13px;
  padding-top: 2.4px;
}

.headingLine {
  display: flex;
  padding: 8px;
}

.headHr hr {
  margin-top: 8px;
  margin-bottom: 0;
}

.userCont {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 100px;
}

.userDetail h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.userDetailInput input {
  width: 92%;
  padding: 11px;
  border-radius: 7px;
  margin-bottom: 14px;
  border: 1px solid gray;
}

.userStay {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
}

.userStay h3 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.userStay h5 {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 6px;
  padding-right: 4px;
  color: gray;
}

.userDate {
  display: flex;
  justify-content: space-between;
}

.userDate h4 {
  margin-top: 0;
  margin-bottom: 0;
  border: 1px solid gray;
  border-radius: 7px;
  padding: 5px 5px;
}

.userDate input {
  padding: 8px 8px;
  border-radius: 6px;
  border: 1 px solid gray;
}

.userRoomCard {
  border: 1px solid gray;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 19px;
  border-radius: 7px;
}

.userRoomInfo {
  margin-bottom: 10px;
}

.userRoomInfo h4 {
  font-size: 16px;
  font-weight: 800;
  margin-top: 0;
  margin-bottom: 0;
}

.userRoomInfo h6 {
  font-size: 13px;
  color: gray;
  margin-top: 2px;
  margin-bottom: 0;
}

.userRoomData select {
  margin-top: 5px;
  margin-bottom: 0;
  width: 100%;
  padding: 10px;
  font-size: 13px;
  font-weight: 700;
  border-radius: 7px;
}

.userRoomData input {
  width: 94%;
  margin-top: 12px;
  margin-bottom: 0;
  padding: 9px;
  font-size: 13px;
  font-weight: 700;
  border-radius: 7px;
  border: 1px solid gray;
}

.userRoomData h5 {
  margin-top: 10px;
  margin-bottom: 0;
  color: blue;
  padding-left: 3px;
}

.userRoomAdd {
  display: flex;
  justify-content: space-between;
  padding-left: 2px;
  margin-top: 12px;
  margin-bottom: 13px;
  /* border: 1px solid gray; */
}

.userRoomText h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
}

.userRoomText h6 {
  margin-top: 0;
  margin-bottom: 0;
}

.qtBtn {
  display: flex;
  border: 1px solid gray;
  border-radius: 7px;
  height: 24px;
  margin-top: 5px;
}

.qtBtn h5 {
  border: 1px solid gray;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0px;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 10px;
}

.qtBtn button {
  background: none;
  border: none;
  font-size: 18px;
  width: 25px;
  height: 25px;
  margin-top: 1px;
  margin-bottom: 0;
}

.addRoomBtn button {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 5px;
  padding: 10px;
  color: black;
  background: white;
  border: 1px solid black;
  border-radius: 7px;
  font-size: 16px;
  font-weight: 700;
}

.addRoomBtn button:hover {
  background: #000;
  color: white;
}

.addnotes input {
  width: 92%;
  padding: 11px;
  border-radius: 7px;
  font-size: 14px;
  border: 1px solid gray;
}

.addNote button {
  width: 100%;
  margin-top: 14px;
  padding: 10px;
  color: gray;
  border: none;
  background: rgb(236, 232, 232);
  border-radius: 7px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}

.addNote button:hover {
  background: #000;
  color: white;
}

.userPrice {
  display: flex;
  justify-content: space-between;
  padding-right: 4px;
}

.userPrice h5 {
  margin-top: 0;
  margin-bottom: 0;
}

.userPrice2 {
  display: flex;
  justify-content: space-between;
  padding-right: 4px;
  margin-left: 10px;
  margin-right: 10px;
}

.userPrice2 h5 {
  margin-top: 0;
  margin-bottom: 0;
}

.createBook {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
}

.createBookBtn button {
  width: 94%;
  margin-top: 14px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  color: gray;
  border: none;
  background: rgb(236, 232, 232);
  border-radius: 7px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
}

.createBookBtn button:hover {
  background: #000;
  color: white;
}

/* Pricing CSS */

.priceHead {
  display: flex;
  justify-content: space-between;
}

.priceHead h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
}

.priceHead i {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 34px;
  color: rgb(23, 206, 23);
  padding-right: 1px;
  padding-top: 10px;
}

.priceData {
  display: flex;
  margin-top: 12px;
}

.priceDate input {
  padding: 4px 6px;
  font-size: 16px;
  border-radius: 15px;
  border: 2px solid black;
  background-color: rgb(236, 235, 235);
}

.priceDrop select {
  padding: 6px 11px;
  font-size: 14px;
  border-radius: 15px;
  border: 2px solid black;
  background-color: rgb(236, 235, 235);
  margin-left: 13px;
}

.priceRate {
  border: 1px solid black;
  border-radius: 7px;
  width: 35%;
  text-align: center;
  margin-top: 13px;
  padding: 10px;
  margin-left: 2px;
}

.priceRate h4 {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10px;
  font-size: 15px;
  font-weight: 800;
}

.priceRate hr {
  width: 30%;
  text-align: center;
}

.priceRate h5 {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 10px;
  font-size: 15px;
}

.priceBook {
  border: 1px solid gray;
  width: 50%;
  border-radius: 7px;
  margin-left: 2px;
  margin-top: 13px;
  padding-top: 12px;
  padding-bottom: 35px;
  padding-left: 13px;
  padding-right: 13px;
}

.priceBook h5 {
  color: green;
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 0;
}

.priceBook h6 {
  font-size: 13px;
  margin-top: 12px;
  margin-bottom: 0;
}

.priceFuture button {
  width: 100%;
  color: black;
  background-color: white;
  border: 2px solid gray;
  border-radius: 7px;
  padding: 9px;
  font-size: 14px;
  font-weight: 700;
  margin-top: 12px;
}

.priceFuture button:hover {
  color: white;
  background-color: black;
}

.pricePromo {
  margin-top: 10px;
}

.priceText1 h2 {
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.priceText2 h2 {
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 10px;
}

.priceIcon .css-i4bv87-MuiSvgIcon-root {
  fill: rgb(47, 226, 47) !important;
  font-size: 42px !important;
  background: rgb(231, 231, 231) !important;
  border-radius: 9px !important;
}

.priceCard {
  display: flex;
  /* border: 1px solid black; */
  padding-top: 11px;
  padding-bottom: 11px;
  padding-right: 10px;
}

.priceInfo {
  padding-left: 16px;
  padding-top: 1px;
}

.priceInfo h4 {
  margin-top: 1px;
  margin-bottom: 0;
}

.priceInfo h5 {
  margin-top: 4px;
  margin-bottom: 0;
  font-weight: 600;
}

.priceTabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 14px !important;
}

.priceTabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  border: 2px solid black !important;
  border-radius: 22px !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}

.priceTabs .css-1wf8b0h-MuiTabs-flexContainer {
  background-color: rgb(223, 222, 222) !important;
  margin-top: 10px !important;
}

.priceTabs .css-1aquho2-MuiTabs-indicator {
  position: relative !important;
}

.css-mmst5v {
  background-color: rgb(223, 222, 222) !important;
}

.allPriceCards {
  background-color: rgb(241, 241, 241);
  padding-top: 3px;
  padding-left: 12.5px;
  padding-bottom: 15px;
}

.pricingCont {
  margin-left: 10px;
  margin-right: 10px;
}

.pricingCont2 {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 70px;
}

.emptyPriceCard {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  padding: 25px 30px;
  /* border: 1px solid gray; */
  /* border-radius: 7px; */
  margin: 8px 12px;
}

.emptyPriceCard img {
  border-radius: 60px;
  width: 90px;
  height: 90px;
}

.emptyPriceCard h3 {
  margin-top: 6px;
  margin-bottom: 0;
}

.emptyPriceCard h4 {
  color: rgb(68, 67, 67);
  margin-top: 15px;
  margin-bottom: 0;
}

.emptyPriceCard button {
  padding: 10px;
  border-radius: 7px;
  width: 100%;
  background: black;
  color: white;
  font-size: 15px;
  font-weight: 700;
  border: none;
  margin-top: 24px;
}
/*  */

/* Earnings CSS */

.tripleNav {
  display: flex;
  justify-content: space-around;
  padding-bottom: 7px;
  /* border: 1px solid black; */
}

.tripleNavText h2 {
  color: black;
  font-weight: 800;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 15px;
}

.tripleNavIcon i {
  padding-top: 18px;
  font-size: 24px;
}

.tripleNavDate h4 {
  padding-top: 20px;
  color: red;
  font-weight: 800;
  margin-top: 0;
  margin-bottom: 0;
}

.tripleNavDate i {
  font-size: 18px;
  font-weight: 800;
}

.earningCont {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 70px;
}

.earningAllCards {
  margin-bottom: 15px;
}

.earningHead h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.earningHead h2 {
  margin-top: 10px;
  margin-bottom: 0;
}

.earningCard {
  display: flex;
  justify-content: space-between;
  padding: 8px 1px;
}

.earningPrice {
  display: flex;
}

.earningCount h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.earningText {
  margin-left: 20px;
}

.earningText h4 {
  margin-top: 0;
  margin-bottom: 0;
  width: 80%;
}

.earningText h5 {
  margin-top: 0;
  margin-bottom: 0;
  color: red;
  padding-top: 5px;
}

.earningText span {
  margin-left: 6px;
  border: 1px solid black;
  padding: 0 7px;
  border-radius: 20px;
}

.earningMoney h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.earningMoney i {
  font-weight: 800;
}

.earningAllLinks {
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
  border-top: 1px solid gray;
  padding: 13px 1px;
}

.earningLinks {
  display: flex;
}

.earningLinks i {
  padding-top: 2px;
  font-size: 22px;
}

.earningLinks h3 {
  padding-left: 10px;
  margin-top: 0;
  margin-bottom: 0;
}

.rightIcon i {
  font-size: 24px;
  font-weight: 800;
  padding-top: 1px;
}

/* EarningDates CSS */

.closeIcon i {
  color: gray;
  padding-left: 13px;
  font-size: 22px;
  margin-top: 0;
  padding-top: 15px;
}

.earnDate {
  margin-top: 10px;
  padding-left: 13px;
  padding-right: 10px;
}

.earnDate h4 {
  margin-top: 15px;
  margin-bottom: 0;
}

/*  */

/* More CSS */

.moreBar {
  display: flex;
  margin-top: 23px;
  padding-left: 10px;
  /* border: 1px solid black; */
}

.moreBar h3 {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 15px;
  padding-top: 4px;
  font-size: 18px;
  font-weight: 500;
}

.moreBar i {
  font-size: 25px;
}

.moreCont {
  height: 130px;
}

.css-9mgopn-MuiDivider-root {
  border-bottom: none !important;
}

.css-jpln7h-MuiTabs-scroller {
  position: static !important;
}

a:-webkit-any-link {
  color: black !important;
}

.css-1rn30mb-MuiCircularProgress-root {
  color: black !important;
}
/*  */

/* SearchBar CSS */

.searchbar {
  display: grid;
  grid-template-columns: 5fr 95fr;
  margin-top: 10px;
  margin-left: 5px;
  text-align: center;
}

.searchInput input {
  text-align: center;
  width: 93%;
  padding: 11px;
  border-radius: 7px;
  margin-left: 10px;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid gray;
}

.searchInput h3:hover {
  background-color: rgb(224, 222, 222);
  margin-left: 15px;
  width: 93%;
  padding: 1px;
  border-radius: 7px;
}
/*  */
/* Notify CSS */

.notifyCont {
  margin-left: 10px;
  margin-right: 10px;
}

.allfilterCard {
  display: grid;
  grid-template-columns: 5fr 95fr;
  margin-top: 10px;
}

.allfilters {
  display: flex;
  overflow-x: scroll;
}

.filterbar {
  margin-right: 10px;
  margin-bottom: 6px;
}

.filterbar h4:hover {
  color: white;
  background-color: black;
}

.filterbar h4 {
  font-size: medium;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 6px 14px;
  margin-top: 0;
  margin-bottom: 0;
  color: gray;
}

.filterbar h3 {
  font-size: medium;
  border: 2px solid black;
  border-radius: 15px;
  padding: 6px 14px;
  margin-top: 0;
  margin-bottom: 0;
}

.notifyText h3 {
  border: 1px solid black;
  padding: 7px;
  text-align: center;
  border-radius: 13px;
  margin-top: 20px;
}
/*  */

/* All Recommend CSS */

.recomCont {
  margin-left: 10px;
  margin-right: 10px;
}

.recomCard {
  display: grid;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 12px;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid gray;
  border-radius: 11px;
  background-color: white;
}

.recomText {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.recomText h2 {
  margin: 0;
  font-size: 18px;
  color: red;
  font-weight: 900;
  padding-bottom: 2px;
}

.recomText i {
  padding-top: 2px;
  font-size: 22px;
  font-weight: 800;
  color: blue;
}

.recomText2 h3 {
  margin: 0;
  font-weight: 800;
  font-size: 15px;
}

.recomText2 h5 {
  margin-top: 4px;
  margin-bottom: 0;
  color: gray;
}

.recomTag {
  display: flex;
  padding-left: 1px;
  margin-bottom: 2px;
  margin-top: 33px;
}

.recomTag h5 {
  margin-top: 0;
  margin-bottom: 3px;
  padding-left: 6px;
  color: rgb(26, 202, 26);
}

.recomTag i {
  color: rgb(26, 202, 26);
}
/*  */

.loader {
  text-align: center;
  margin-top: 220px;
}
/*  */
/* MyGopartner CSS */

.myGotext {
  font-size: 20px;
  border-top: 1px solid gray;
  margin-top: 15px;
  margin-bottom: 25px;
  padding-top: 8px;
  padding-left: 13px;
}

.myGotext h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.myGocard {
  margin-left: 11px;
  margin-right: 13px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid gray;
}

.myGoinfo {
  display: grid;
  grid-template-columns: 10fr 88fr 2fr;
}

.myGoinfo h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.myGoinfo i:first-of-type {
  font-size: 22px;
  padding-top: 3px;
}

.myGoinfo i {
  font-size: 18px;
  padding-top: 4px;
  padding-right: 3px;
}

.myGodetail h5 {
  color: gray;
  font-weight: 600;
  font-size: 14px;
  padding-right: 5px;
  margin-top: 4px;
  margin-bottom: 0;
  margin-left: 10%;
}
/*  */
/* Kyc CSS */

.myGotext2 {
  font-size: 20px;
  border-top: 1px solid gray;
  margin-top: 4px;
  margin-bottom: 25px;
  padding-top: 8px;
  padding-left: 13px;
}

.myGotext2 h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.kycBox {
  border-bottom: 1px solid gray;
  margin-left: 13px;
  margin-right: 13px;
}

.myGoinfo2 {
  display: grid;
  grid-template-columns: 10fr 88fr 2fr;
  margin-bottom: 40px;
}

.myGoinfo2 h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 19px;
}

.myGoinfo2 i:first-of-type {
  font-size: 20px;
  padding-top: 4px;
}

.myGoinfo2 i {
  font-size: 18px;
  padding-top: 4px;
  padding-right: 3px;
}
/*  */
/* Personal CSS */

.fourHead {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid gray;
}

.fourInfo {
  display: flex;
}

.fourText h2 {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 13px;
  padding-top: 2px;
  font-size: 20px;
}

.fourIcon {
  padding-top: 1px;
  font-size: 20px;
}

.fourIcon i {
  padding-left: 20px;
}

.fourIcon h5 {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  color: red;
}

.step1Info {
  font-size: 16px;
  margin-top: 13px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-left: 13px;
}

.step1Info h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.step1Info p {
  margin-top: 5px;
  margin-bottom: 20px;
  color: gray;
}

.step1Input {
  margin-left: 13px;
  margin-right: 13px;
  margin-top: 15px;
  position: relative;
}

.step1Input label {
  font-size: 14px;
}

.step1Input input {
  width: 95%;
  padding: 9px;
  font-size: 16px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid gray;
}

.step1Input i {
  position: absolute;
  right: 12px;
  top: 35px;
  color: gray;
}
/*  */
/* Property CSS */

.uploadImg {
  border: 1px solid gray;
  border-radius: 5px;
  text-align: center;
  margin-left: 13px;
  padding: 50px 25px;
  width: 33%;
  margin-top: 5px;
}

.uploadImg i {
  font-size: 24px;
}

.uploadImg h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.allproCard {
  display: grid;
  grid-template-columns: 50fr 50fr;
  margin-left: 13px;
  margin-right: 13px;
  margin-top: 5px;
  column-gap: 13px;
}

.proCard {
  text-align: center;
  border: 1px solid gray;
  border-radius: 5px;
  background: rgb(235, 232, 232);
  padding-bottom: 8px;
  margin-bottom: 18px;
}

.proCard img {
  border-radius: 5px;
  height: 100px;
  width: 100%;
}

.proCard h4 {
  margin-top: 0;
  margin-bottom: 0;
}
/*  */
/* Map CSS */

.mapPin {
  padding-left: 13px;
  padding-bottom: 12px;
  margin-top: 20px;
}

.mapPin h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 17px;
}

.mapPin h5 {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}
/*  */
/* Profile CSS */

.profileCont {
  margin-top: 20px;
  margin-bottom: 20px;
}

.profileHead {
  text-align: center;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 1px 6px 0px;
  margin-left: 13px;
  margin-right: 13px;
  padding-top: 13px;
  padding-bottom: 13px;
}

.profileHead img {
  width: 110px;
  height: 110px;
  border-radius: 60px;
}

.profileHead h3 {
  margin-top: 10px;
  margin-bottom: 0;
}

.profileHead h4 {
  margin-top: 12px;
  margin-bottom: 0;
}

.profileText {
  margin-top: 22px;
  margin-bottom: 0;
  margin-left: 13px;
}

.profileText h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.profileInfo {
  background: white;
  border-radius: 5px;
  box-shadow: 0px 1px 6px 0px;
  margin-left: 13px;
  margin-right: 13px;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 12px;
}

.profileInfo h3 {
  margin-top: 5px;
  margin-bottom: 0;
}

.profileInfo h3 i {
  padding-right: 15px;
  font-size: 20px;
}

.profileInfo h4 i {
  padding-right: 15px;
  font-size: 20px;
}

.profileInfo h4 {
  margin-top: 15px;
  margin-bottom: 0;
}
/*  */
/* Rooms CSS */

.allroomBox {
  display: flex;
  border-top: 1px solid gray;
  padding-top: 20px;
  padding-left: 13px;
}

.roomBox {
  padding-right: 50px;
}

.roomText h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
}

.roomNum {
  display: flex;
  padding-top: 1px;
}

.roomNum h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.roomNum button {
  color: rgb(39, 39, 187);
  background: white;
  border: none;
  font-weight: 700;
  font-size: 15px;
  margin-left: 6px;
  margin-top: 3px;
}

.roomDesc {
  color: rgb(204, 204, 37);
  background: rgb(255, 255, 195);
  padding: 12px;
  margin-top: 13px;
  margin-left: 10px;
  margin-right: 10px;
}

.roomDesc h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.roomDesc i {
  padding-right: 10px;
}

.roomCard {
  display: flex;
  justify-content: space-between;
  margin: 22px 13px;
}

.roomInfo h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
}

.roomInfo h5 {
  margin-top: 0;
  margin-bottom: 0;
  color: gray;
  font-size: 16px;
  font-weight: 600;
}

.roomInfo2 {
  border: 1px solid gray;
  border-radius: 7px;
  width: 48%;
  text-align: center;
  margin-top: 5px;
}

.roomInfo2:hover {
  background: #000;
  color: white;
}

.roomInfo2 h3 {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 6px;
  font-weight: 600;
}

.roomInfo2 i {
  padding-right: 12px;
}
/*  */
/* AddRoom CSS */

.roomCont {
  position: relative;
}

.roomHead {
  padding-left: 13px;
  padding-top: 13px;
  border-top: 1px solid gray;
}

.roomHead h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.allroomList {
  display: grid;
  grid-template-columns: 20fr 30fr 20fr 30fr;
}

.roomList {
  display: grid;
  justify-content: center;
  margin-top: 10px;
  padding-left: 13px;
  padding-right: 13px;
  text-align: center;
}

.roomList h3 {
  margin-top: 0;
  margin-bottom: 12px;
  color: gray;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 7px;
  border-bottom: 0.2px solid gray;
}

.roomList h4 {
  margin-top: 0;
  margin-bottom: 0;
  color: gray;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid gray;
  border-radius: 5px;
  height: 26px;
  width: 50px;
  padding: 0px 2px;
}

.roomList h5 {
  margin-top: 0;
  margin-bottom: 0;
  color: gray;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid gray;
  border-radius: 5px;
  height: 26px;
  width: 40px;
  padding: 0px 2px;
}

.roomList input {
  font-size: 16px;
  font-weight: 600;
  color: gray;
  border: 1px solid gray;
  border-radius: 5px;
  width: 40px;
  height: 26px;
  text-align: center;
}

.roomList select {
  text-align: center;
  color: gray;
  font-weight: 600;
  width: 100px;
  border-radius: 5px;
  border: 1px solid gray;
  padding: 5px;
}

.roomList button {
  border: 1px solid black;
  border-radius: 5px;
  width: 100px;
  background: white;
  font-size: 14px;
}

.roomList button:hover {
  background: black;
  color: white;
}

.roomList button i {
  padding-right: 10px;
}

.submitRoom button {
  margin-bottom: 10px;
  position: fixed;
  bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
  width: 94%;
  background: #000;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 15px;
}
/*  */
/* EditFloor CSS */

.selectFloor {
  display: flex;
  justify-content: space-between;
  margin-left: 13px;
  margin-right: 13px;
  margin-top: 20px;
}

.floorText h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 17px;
}

.floorText h5 {
  margin-top: 0;
  margin-bottom: 0;
  color: gray;
  font-size: 14px;
  margin-top: 2px;
}

.floorBtn {
  display: flex;
  border: 1px solid gray;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 4px;
  padding-bottom: 8px;
  height: 18px;
  margin-top: 6px;
}

.floorBtn h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  padding-left: 15px;
  padding-right: 15px;
}

.floorBtn i {
  padding-top: 4px;
  padding-bottom: 4px;
}

.floorInput label {
  font-size: 14px;
  padding-left: 2px;
}

.floorInput input {
  width: 140px;
  padding: 7px;
  text-align: center;
  border: 1px solid gray;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 3px;
}
/*  */
/* Policy CSS */

.policyCont {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 18px;
  margin-bottom: 80px;
}

.policyText {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  background: white;
  box-shadow: 0 2px 9px 2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 18px 15px;
  margin-bottom: 26px;
  border-radius: 4px;
}

.policyText h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 17px;
}

.policyText i {
  font-size: 18px;
  font-weight: 800;
  padding-top: 4.2px;
  padding-right: 3px;
}
/*  */
.monthDrops select {
  margin-top: 10px;
  padding: 5px;
  font-size: 14px;
  width: 85%;
  border-radius: 3px;
  border: 1px solid gray;
}
/*  */
